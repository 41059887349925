<template>
  <div
    class="appConfig"
    style="margin: auto; margin-bottom: 20px; width: 400px"
  >
    <!-- <div class="theThird"> -->
    <el-switch
      v-model="NeedGithubOrLDAP"
      active-text="第三方授权表单"
      style="width: 400px"
    ></el-switch>
    <!-- </div> -->
    <el-form
      :model="SSO_CONFIG_GITHUB_OR_LDAP.config"
      v-if="NeedGithubOrLDAP"
      ref="SSO_LOGIN"
    >
      <el-form-item>
        <el-radio-group v-model="GithubOrLDAP">
          <el-radio-button label="Github"></el-radio-button>
          <el-radio-button label="LDAP"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-for="(item, index) in appConfigSSO"
        :key="index"
        :label="item.label"
        :prop="item.name"
        :rules="item.rules"
        :placeholder="item.placeholder"
      >
      <p class="callback" v-if="item.formType === 'callback'">
        {{item.content+"https://" + $store.getters.reqData.clusterPrimaryKey + "." + $store.getters.reqData.cloud + ".dmetasoul.com/"}}
      </p>
        <el-input
          v-if="item.formType === 'input'"
          v-model="SSO_CONFIG_GITHUB_OR_LDAP.config[item.name]"
          :type="item.type"
          :disabled="item.disabled ? item.disabled : false"
        ></el-input>

        <el-select
          v-if="item.formType === 'select'"
          :placeholder="item.placeholder"
          v-model="SSO_CONFIG_GITHUB_OR_LDAP.config[item.name]"
        >
          <el-option
            v-for="(opt, index) in selectsOptions.SSO_CONFIG_GITHUB_SELECT"
            :key="index"
            :label="opt.label"
            :value="opt.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-form :model="appConfig" ref="appConfig">
      <el-form-item
        v-for="(item, index) in appConfigObj"
        :key="index"
        :label="item.label"
        :prop="item.name"
        :rules="item.rules"
        :placeholder="item.placeholder"
      >
        <!-- 普通输入框 -->
        <el-input
          v-if="item.formType === 'input'"
          v-model="appConfig[item.name]"
          :type="item.type"
          :disabled="item.disabled ? item.disabled : false"
        ></el-input>

        <!-- 下拉框 -->

        <el-select
          v-if="item.formType === 'select'"
          :placeholder="item.placeholder"
          v-model="appConfig[item.name]"
        >
          <el-option
            v-for="(opt, index) in selectsOptions[item.name]"
            :key="index"
            :label="opt.label"
            :value="opt.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 暂时不显示 -->
      <!-- <el-switch
        v-model="value"
        :active-text="INFORM_MESSAGE.RDS"
        @click="showMoreOrNot"
        style="width: 400px"
      /> -->
      <!-- <br />
      <el-form v-if="showFlag">
        <el-form-item
          v-for="(itemRDS, index) in appConfigObjRDS"
          :key="index"
          :label="itemRDS.label"
          :prop="itemRDS.name"
          :rules="itemRDS.rules"
          :placeholder="itemRDS.placeholder"
        > -->
          <!-- 普通输入框 -->
          <!-- <el-input
            v-if="itemRDS.formType === 'input'"
            v-model="appConfig[itemRDS.name]"
          ></el-input>
        </el-form-item>
      </el-form> -->
    </el-form>
    <div id="zhanwei"></div>
    <PrevNext @prev-click="onPrevClick" @next-click="onNextClick()"></PrevNext>
  </div>
</template>

<script>
import appConfigObj, {
  SSO_CONFIG,
} from "../../../assets/formObj/commonAppConfig.js";
import SSO_LOGIN, { Github } from "../../../assets/formObj/SSO_login";
import PrevNext from "../../Common/PrevNext.vue";
import {
  PROCESS_ACTIVE,
  DEFUALT_INPUT,
  INFORM_MESSAGE,
} from "../../../assets/constant.js";

export default {
  components: { PrevNext },
  created() {
    this.appConfigObj = JSON.parse(JSON.stringify(this.appConfigObj));
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.APPCONFIG);
    console.log(this.appConfigObj);
    for (let key in this.appConfigObj) {
      if (this.appConfigObj[key].name.indexOf("RDS_") !== -1) {
        this.appConfigObjRDS[key] = this.appConfigObj[key];
        delete this.appConfigObj[key];
      }
    }
    if (this.$store.getters.reqData?.appCommonConfig?.SSO_CONFIG == {}) {
      this.SSO_CONFIG_GITHUB_OR_LDAP = { type: "Github", config: {github_use_login_as_id:false} };
    }
    console.log(this.appConfigObjRDS);
  },
  computed: {},
  data() {
    return {
      GithubOrLDAP:
        this.$store.getters.reqData?.appCommonConfig?.SSO_CONFIG?.type ||
        "Github",
      NeedGithubOrLDAP: this.decideNeedGithubOrLDAP(),
      showFlag: false,
      selectsOptions: {
        // DMETASOUL_REPO_TAG: [
        //   {
        //     label: "dmetasoul-v1.0.1",
        //     value: "dmetasoul-v1.0.1",
        //   },
        // ],
        SSO_CONFIG_GITHUB_SELECT: [
          {
            label: "true",
            value: "true",
          },
          {
            label: "false",
            value: "false",
          },
        ],
      },
      SSO_CONFIG_GITHUB_OR_LDAP: this.result(),
      appConfigObjRDS: new Object(),
      appConfigObj: appConfigObj,
      appConfig: {
        DMETASOUL_CLOUD: this.$store.getters.reqData.cloud.toUpperCase(),
        AWS_SECRET_ACCESS_KEY:
          this.$store.getters.reqData?.appCommonConfig?.AWS_SECRET_ACCESS_KEY,
        AWS_ACCESS_KEY_ID:
          this.$store.getters.reqData?.appCommonConfig?.AWS_ACCESS_KEY_ID,
        DMETASOUL_SERVER_NODE:
          this.$store.getters.reqData.nodesOptions[0]?.nodeLabels.nodestype,
        DMETASOUL_TASK_NODE:
          this.$store.getters.reqData.nodesOptions[2]?.nodeLabels.nodestype,
        USER: "admin@example.com",
        DMETASOUL_PASSWORD:
          this.$store.getters.reqData?.appCommonConfig?.DMETASOUL_PASSWORD,
        AWS_ENDPOINT:
          this.$store.getters.reqData?.appCommonConfig?.AWS_ENDPOINT,
        DMETASOUL_S3_PREFIX:
          this.$store.getters.reqData?.appCommonConfig?.DMETASOUL_S3_PREFIX,
        DMETASOUL_REPO_PREFIX: DEFUALT_INPUT.DMETASOUL_REPO_PREFIX,
        DMETASOUL_REPO_TAG:
          this.$store.getters.reqData?.appCommonConfig?.DMETASOUL_REPO_TAG||"dmetasoul-v1.0.1",

        DMETASOUL_INGRESS_HOST:
          this.$store.getters.reqData?.appCommonConfig?.DMETASOUL_INGRESS_HOST,
        DMETASOUL_INGRESS_ENABLED: DEFUALT_INPUT.DMETASOUL_INGRESS_ENABLED,
        DMETASOUL_NAMESPACE:
          this.$store.getters.reqData?.appCommonConfig?.DMETASOUL_NAMESPACE ||
          DEFUALT_INPUT.DMETASOUL_NAMESPACE,
        DMETASOUL_SERVICEACCOUNT:
          this.$store.getters.reqData?.appCommonConfig
            ?.DMETASOUL_SERVICEACCOUNT ||
          DEFUALT_INPUT.DMETASOUL_SERVICEACCOUNT,
        RDS_MYSQL_HOST:
          this.$store.getters.reqData?.appCommonConfig?.RDS_MYSQL_HOST || "",
        RDS_MYSQL_PORT:
          this.$store.getters.reqData?.appCommonConfig?.RDS_MYSQL_PORT || "",
        RDS_MYSQL_USER:
          this.$store.getters.reqData?.appCommonConfig?.RDS_MYSQL_USER || "",
        RDS_MYSQL_PASSWORD:
          this.$store.getters.reqData?.appCommonConfig?.RDS_MYSQL_PASSWORD ||
          "",
        RDS_MYSQL_DATABASE:
          this.$store.getters.reqData?.appCommonConfig?.RDS_MYSQL_DATABASE ||
          "",
        SSO_CONFIG: this.$store.getters.reqData?.appCommonConfig
          ?.SSO_CONFIG || { type: "Github", config: "" },
      },
      INFORM_MESSAGE: INFORM_MESSAGE,
    };
  },
  computed: {
    appConfigSSO: function () {
      if (this.GithubOrLDAP == "Github") return SSO_LOGIN.Github;
      else return SSO_LOGIN.LDAP;
    },
  },
  watch: {
    SSO_CONFIG_GITHUB_OR_LDAP: {
      handler(newvalue, oldvalue) {
        this.appConfig.SSO_CONFIG = newvalue;
      },
      deep: true,
    },
  },
  methods: {
    decideNeedGithubOrLDAP() {
      let SSO_CONFIG = this.$store.getters.reqData?.appCommonConfig?.SSO_CONFIG;
      if (SSO_CONFIG !== undefined) {
        if (Object.keys(SSO_CONFIG).length !== 0) {
          return true;
        }
      }
      return false;
    },
    result() {
      let SSO_CONFIG = this.$store.getters.reqData?.appCommonConfig?.SSO_CONFIG;
      if (SSO_CONFIG !== undefined) {
        if (Object.keys(SSO_CONFIG).length == 0) {
          return {
            type: "Github",
            config: {github_use_login_as_id:false},
          };
        } else {
          return this.$store.getters.reqData?.appCommonConfig?.SSO_CONFIG;
        }
      } else {
        return {
          type: "Github",
          config: {github_use_login_as_id:false},
        };
      }
    },
    onPrevClick() {
      // this.$store.dispatch("setAppCommonConfig", this.appConfig);//为跑通功能配合联调暂时注释20220505
      this.$router.push("/form/"+this.$store.getters.reqData.cloud+"Access/"+this.$store.getters.reqData.cloud+"Cluster/node");
    },

    showMoreOrNot() {
      this.showFlag = !this.showFlag;
      console.log(this.showFlag);
      console.log(this.appConfigObjRDS);
    },

    onNextClick(to) {
      this.$refs["appConfig"].validate((valid) => {
        if (!!valid) {
          //IDE密码加密
          this.appConfig.DMETASOUL_PASSWORD = this.$getRsaCode(
            this.appConfig.DMETASOUL_PASSWORD
          );
          if (this.NeedGithubOrLDAP == false) {
            // 若不需要 SSO_LOGIN 直接设为空跳转
            this.appConfig.SSO_CONFIG = {};
            this.$store.dispatch("setAppCommonConfig", this.appConfig);
            console.log(this.$store.getters.reqData);
            this.$router.push("/form/appCustomConfig");
          } else {
            // 需要 SSO_LOGIN 进一步校验
            this.$refs["SSO_LOGIN"].validate((valid2) => {
              if (!!valid2) {
                this.appConfig.SSO_CONFIG.type = this.GithubOrLDAP;
                if (this.GithubOrLDAP == "Github") {
                  for (let item in SSO_LOGIN.LDAP) {
                    delete this.appConfig.SSO_CONFIG.config[item];
                  }
                } else {
                  for (let item in SSO_LOGIN.Github) {
                    delete this.appConfig.SSO_CONFIG.config[item];
                  }
                }
                this.$store.dispatch("setAppCommonConfig", this.appConfig);
                console.log(this.$store.getters.reqData);
                this.$router.push("/form/appCustomConfig");
              } else {
                ElMessage({
                  showClose: true,
                  message: "输入不符合规则，请重新输入",
                  type: "error",
                });
              }
            });
          }
        } else {
          ElMessage({
            showClose: true,
            message: "输入不符合规则，请重新输入",
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
#zhanwei {
  height: 50px;
}
.callback{
  font-size: 10px;
  color: red;
}
</style>