
module.exports = {
    // DMETASOUL_CLOUD: {
    //     label: '当前云商',
    //     type: "DMETASOUL_CLOUD",
    //     formType: "input",
    //     name: "DMETASOUL_CLOUD",
    //     placeholder: '',
    //     rules: [{ required: true, message: '请填写当前云商' }],
    // },
    AWS_ENDPOINT: {
        label: 'S3 Endpoint地址',
        type: "AWS_ENDPOINT",
        formType: "input",
        name: "AWS_ENDPOINT",
        placeholder: '',
        rules: [{ required: true, message: '请填写host地址' }],
    },
    AWS_ACCESS_KEY_ID: {
        label: 'S3 accesskeyID',
        type: "AWS_ACCESS_KEY_ID",
        formType: "input",
        name: "AWS_ACCESS_KEY_ID",
        placeholder: '',
        rules: [{ required: true, message: '请填写accesskeyID' }],
    },
    AWS_SECRET_ACCESS_KEY: {
        label: 'S3 accesskeySecret',
        type: "password",
        formType: "input",
        name: "AWS_SECRET_ACCESS_KEY",
        placeholder: '',
        rules: [{ required: true, message: '请填写accesskey' }],
    },
    DMETASOUL_S3_PREFIX: {
        label: 'S3 存储路径',
        type: "DMETASOUL_S3_PREFIX",
        formType: "input",
        name: "DMETASOUL_S3_PREFIX",
        placeholder: '',
        rules: [{ required: true, message: '请填写存储路径' }],
    },
    // DMETASOUL_REPO_PREFIX: {
    //     label: '镜像URL',
    //     type: "DMETASOUL_REPO_PREFIX",
    //     formType: "input",
    //     name: "DMETASOUL_REPO_PREFIX",
    //     placeholder: '',
    //     rules: [{ required: true, message: '请填写镜像URL' }],
    // },
    // DMETASOUL_REPO_TAG: {
    //     label: '镜像TAG',
    //     type: "DMETASOUL_REPO_TAG",
    //     formType: "select",
    //     name: "DMETASOUL_REPO_TAG",
    //     placeholder: '请选择TAG',
    //     rules: [{ required: true, message: '请选择镜像TAG' }],
    // },
    // DMETASOUL_NAMESPACE: {
    //     label: '应用部署namepace',
    //     type: "DMETASOUL_NAMESPACE",
    //     formType: "input",
    //     name: "DMETASOUL_NAMESPACE",
    //     placeholder: '',
    //     rules: [{ required: true, message: '请填写应用部署namepace' }],
    // },
    // DMETASOUL_INGRESS_HOST: {
    //     label: '集群Ingress入口地址',
    //     type: "DMETASOUL_INGRESS_HOST",
    //     formType: "input",
    //     name: "DMETASOUL_INGRESS_HOST",
    //     placeholder: '',
    //     rules: [{ required: true, message: '请填写公共host' }],
    // },
    // DMETASOUL_INGRESS_ENABLED: {
    //     label: 'ingress',
    //     type: "DMETASOUL_INGRESS_ENABLED",
    //     formType: "input",
    //     name: "DMETASOUL_INGRESS_ENABLED",
    //     placeholder: '',
    //     rules: [{ required: true, message: '请填写ingress' }],
    // },

    // DMETASOUL_SERVER_NODE: {
    //     label: '应用部署namepace',
    //     type: "DMETASOUL_SERVER_NODE",
    //     formType: "input",
    //     name: "DMETASOUL_SERVER_NODE",
    //     placeholder: '',
    //     rules: [{ required: true, message: '请填写应用部署namepace' }],
    // },    
    // DMETASOUL_TASK_NODE: {
    //     label: '应用部署namepace',
    //     type: "DMETASOUL_TASK_NODE",
    //     formType: "input",
    //     name: "DMETASOUL_TASK_NODE",
    //     placeholder: '',
    //     rules: [{ required: true, message: '请填写应用部署namepace' }],
    // },
    USER: {
        label: '管理员用户名',
        type: "text",
        formType: "input",
        name: "USER",
        placeholder: '',
        rules: [
            { required: true, message: '请填写管理员用户名' },
            { type: 'email', message: '邮箱不合法' },
        ],
    },
    DMETASOUL_PASSWORD: {
        label: '管理员登录密码',
        type: "password",
        formType: "input",
        name: "DMETASOUL_PASSWORD",
        placeholder: '',
        rules: [{ required: true, message: '请填写管理员登录密码' }],
    },
    DMETASOUL_NAMESPACE: {
        label: 'DMETASOUL_NAMESPACE',
        type: "DMETASOUL_NAMESPACE",
        formType: "input",
        name: "DMETASOUL_NAMESPACE",
        placeholder: '',
        disabled: true,
        rules: [{ required: true, message: '请填写DMETASOUL_NAMESPACE' }],
    },
    DMETASOUL_SERVICEACCOUNT: {
        label: 'DMETASOUL_SERVICEACCOUNT',
        type: "DMETASOUL_SERVICEACCOUNT",
        formType: "input",
        name: "DMETASOUL_SERVICEACCOUNT",
        placeholder: '',
        disabled: true,
        rules: [{ required: true, message: '请填写DMETASOUL_SERVICEACCOUNT' }],
    },
    RDS_MYSQL_HOST: {
        label: '远程MySQL 主机',
        type: "RDS_MYSQL_HOST",
        formType: "input",
        name: "RDS_MYSQL_HOST",
        placeholder: '',
        rules: [],
    },
    RDS_MYSQL_PORT: {
        label: '远程MySQL 端口',
        type: "RDS_MYSQL_PORT",
        formType: "input",
        name: "RDS_MYSQL_PORT",
        placeholder: '',
        rules: [],
    },
    RDS_MYSQL_USER: {
        label: '远程MySQL 用户名',
        type: "RDS_MYSQL_USER",
        formType: "input",
        name: "RDS_MYSQL_USER",
        placeholder: '',
        rules: [],
    },
    RDS_MYSQL_PASSWORD: {
        label: '远程MySQL 密码',
        type: "password",
        formType: "input",
        name: "RDS_MYSQL_PASSWORD",
        placeholder: '',
        rules: [],
    },
    RDS_MYSQL_DATABASE: {
        label: '远程MySQL 数据库名',
        type: "RDS_MYSQL_DATABASE",
        formType: "input",
        name: "RDS_MYSQL_DATABASE",
        placeholder: '',
        rules: [],
    },
}