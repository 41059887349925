module.exports = {
    Github:{
        github_callback: {
            formType: "callback",
            content: "* 请前往github获取github_client_id,github_client_secret等信息,请将callback地址设置为：",
        },
        github_client_id: {
            label: 'github_client_id',
            type: "github",
            formType: "input",
            name: "github_client_id",
            value: false,
            rules: [{ required: true, message: '请填写github_client_id' }],
        },
        
        github_client_secret: {
            label: 'github_client_secret',
            type: "github",
            formType: "input",
            name: "github_client_secret",
            placeholder: '',
            rules: [{ required: true, message: '请填写github_client_secret' }],
        },
        
        
        github_hostname: {
            label: 'github_hostname',
            type: "github",
            formType: "input",
            name: "github_hostname",
            placeholder: '',
            rules: [],
        },

        
        github_org_name: {
            label: 'github_org_name',
            type: "github",
            formType: "input",
            name: "github_org_name",
            placeholder: '',
            rules: [{ required: true, message: '请填写github_org_name' }],
        },

        
        github_use_login_as_id: {
            label: 'github_use_login_as_id',
            type: "github",
            formType: "select",
            name: "github_use_login_as_id",
            placeholder: '请选择',
            rules: [{ required: true, message: '请选择github_use_login_as_id' }],
        },
    },
    LDAP:{
        ldap_host: {
            label: 'ldap_host',
            type: "type",
            formType: "input",
            name: "ldap_host",
            placeholder: '',
            rules: [{ required: true, message: '请填写ldap_host' }],
        },
        ldap_bind_on: {
            label: 'ldap_bind_on',
            type: "type",
            formType: "input",
            name: "ldap_bind_on",
            placeholder: '',
            rules: [{ required: true, message: '请填写ldap_bind_on' }],
        },
        ldap_bind_pw: {
            label: 'ldap_bind_pw',
            type: "type",
            formType: "input",
            name: "ldap_bind_pw",
            placeholder: '',
            rules: [{ required: true, message: '请填写ldap_bind_pw' }],
        },
        ldap_user_base_dn: {
            label: 'ldap_user_base_dn',
            type: "type",
            formType: "input",
            name: "ldap_user_base_dn",
            placeholder: '',
            rules: [{ required: true, message: '请填写ldap_user_base_dn' }],
        },
        ldap_group_base_dn: {
            label: 'ldap_group_base_dn',
            type: "type",
            formType: "input",
            name: "ldap_group_base_dn",
            placeholder: '',
            rules: [{ required: true, message: '请填写ldap_group_base_dn' }],
        }
    }
}